import axios from "axios";

export const postCreateCbPortalSessionApi = (data: { customerId: string }) => {
    return axios.post(route("api.chargebee.createPortalSession"), data);
};

export const validateEmailApi = (email: string, apiKey: string) => {
    const response = axios.get(
        `https://emailvalidation.abstractapi.com/v1/?api_key=${apiKey}&email=${email}`
    );
    return response;
};
export const verifyChangeEmailPinApi = (pinCode: string, NewEmail: string) => {
    return axios.post(route("parents.verify-change-email-pin"), {
        pin: pinCode,
        email: NewEmail
    });
};

export const changeEmailRequestApi = (newEmail: string) => {
    return axios.post(route("parents.change-email-submit"), {
        email: newEmail
    });
};

export const cancelProgramApi = (data: {
    type: "addon" | "plan";
    licenseId?: number;
    itemId?: number;
    studentId: number;
    reasonId: number | null;
    cancellationType: "refund" | "non-refund";
}) => {
    return axios.post(route("api.subscription.cancel"), data);
};

export const manageRegistrationsApi = (studentId: number) => {
    return axios.get(route("parents.manage-registrations", studentId));
};
